import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    class: _normalizeClass([
      {
        'click-reveal-open': _ctx.isOpen,
      },
      'click-reveal',
    ])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_icon, {
        class: _normalizeClass([
        {
          'rotate-open': _ctx.isOpen,
        },
        'caret',
      ]),
        icon: _ctx.caretForwardOutline
      }, null, 8, ["class", "icon"]),
      _createElementVNode("p", null, _toDisplayString(_ctx.label), 1),
      _createVNode(_component_ion_button, {
        disabled: _ctx.disableAdd,
        class: "add-pickup",
        fill: "clear",
        onClick: _ctx.setIsOpen
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            class: "add-icon",
            icon: _ctx.addOutline,
            slot: "start"
          }, null, 8, ["icon"]),
          _createTextVNode(" " + _toDisplayString(_ctx.addLabel), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ]),
    _: 1
  }, 8, ["class"]))
}