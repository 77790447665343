
import { IonItem, IonIcon, IonButton } from "@ionic/vue";
import { caretForwardOutline, addOutline } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  components: { IonItem, IonButton, IonIcon },
  props: {
    isOpen: { type: Boolean },
    setIsOpen: { type: Function },
    label: { type: String },
    addLabel: { type: String },
    disableAdd: { type: Boolean },
  },
  setup() {
    return {
      addOutline,
      caretForwardOutline,
    };
  },
});
