
import {
  IonItem,
  IonButton,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonToggle,
  // IonSpinner,
} from "@ionic/vue";
import { defineComponent, toRefs, ref } from "vue";
import { categories } from "@/lib/constants";
import DlvrSelect from "@/components/DlvrSelect.vue";
import DlvrInput from "@/components/DlvrInput.vue";
import DlvrDatePicker from "@/components/DlvrDatePicker.vue";
// import { bookingForm } from "@/store";
// import { openToast } from "@/composables/ionicControllers";

export default defineComponent({
  components: {
    IonItem,
    DlvrInput,
    DlvrDatePicker,
    IonButton,
    IonRadioGroup,
    IonRadio,
    IonLabel,
    IonToggle,
    // IonSpinner,
    DlvrSelect,
  },
  props: {
    onSave: { type: Function },
    isPickup: { type: Boolean },
    data: { type: Object },
    validationRules: { type: Object },
    selectVehicle: { type: Function },
    modelValue: String,
  },
  setup(props, { emit }) {
    const { data: initialData } = toRefs(props);
    const pickup = ref(initialData?.value?.pickup);
    const delivery = ref(initialData?.value?.delivery);
    // const isPricingLoading = ref(false);
    // const pricingInfo = ref<any>(null);
    // const vehicles = ref<any>([]);
    // const selectedState = ref("");

    const onPickupAddressChanged = async (address: any) => {
      // if (
      //   selectedState.value !==
      //   address?.address_components[5].long_name.toLowerCase()
      // ) {
      //   isPricingLoading.value = true;
      //   const result = await bookingForm.getMerchantPricingInformationByState(
      //     address?.address_components[5].long_name.toLowerCase(),
      //   );
      //   if (result.ok) {
      //     isPricingLoading.value = false;
      //     selectedState.value = address?.address_components[5].long_name.toLowerCase();
      //     pricingInfo.value = result.data[0];
      //     console.log(result.data[0]);
      //     vehicles.value = Object.keys(result.data[0].vehicles);
      //   } else {
      //     await openToast("could not load pricing for this agent");
      //   }
      // }
      pickup.value.fullAddress = address;
    };

    const updateModelValue = (evt: any) => {
      emit("update:modelValue", evt.target.value);
    };
    return {
      pickup,
      delivery,
      // vehicles,
      categories,
      updateModelValue,
      // isPricingLoading,
      onPickupAddressChanged,
    };
  },
});
