
import { ref, toRefs } from 'vue';
import { IonIcon, IonButton, IonContent } from '@ionic/vue';
import { closeOutline, chevronBackOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

import DlvrInput from '@/components/DlvrInput.vue';

export default defineComponent({
  components: { IonIcon, IonButton, IonContent, DlvrInput },
  props: {
    close: { type: Function },
    data: { type: Object },
    formData: { type: Object },
    onConfirm: { type: Function },
    validationRules: { type: Object },
  },
  setup(props) {
    const { formData } = toRefs(props);
    const initialData = ref(formData);
    const activeTab = ref<number>(0);
    return {
      customerInfo: initialData,
      activeTab,
      closeOutline,
      chevronBackOutline,
    };
  },
});
