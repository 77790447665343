
import Joi from 'joi';
import { getDispatcherLocationGeoFireInstance } from '@/lib/firebase';
import { useRoute } from 'vue-router';
import {
  IonContent,
  IonPage,
  IonButton,
  IonImg,
  IonItem,
  loadingController,
} from '@ionic/vue';
import { defineComponent, ref, reactive, onMounted } from 'vue';
import AnimatedButton from './AnimatedButton.vue';
import DetailsForm from './Form.vue';
import ConfirmationModal from './ConfirmationModal.vue';
import StatusModal from './StatusModal.vue';
import { validateFields } from '@/lib/forms';
import {
  loading,
  openToast,
  modal,
} from '@/composables/ionicControllers';
import { PickUp, Delivery } from '@/types/bookingForm';
import { bookingForm } from '@/store';
import { getConfig } from '@/lib/constants';
import { parseStateFromLocation } from '@/lib/helper';

declare var PaystackPop: any;

const geoFire = getDispatcherLocationGeoFireInstance();

export default defineComponent({
  components: {
    DetailsForm,
    IonContent,
    IonPage,
    IonItem,
    IonImg,
    IonButton,
    AnimatedButton,
  },
  setup() {
    const route = useRoute();
    const radius = 50; // KM
    const isPickupOpen = ref<boolean>(false);
    const isDeliveryOpen = ref<boolean>(false);
    const deliveries = ref<Delivery[]>([]);
    const pickups = ref<PickUp[]>([]);
    const loadingAgentInformation = ref<boolean>(false);
    // eslint-disable-next-line no-undef
    const modalValue = ref<HTMLIonModalElement | undefined>(
      undefined,
    );
    const activeLocations = reactive<{ [key: string]: any }>({});
    const total = ref<number>(0);
    const agentInfo = reactive({
      isActive: false,
      companyName: '',
      logo: '',
    });
    const customerInfo = reactive({
      name: '',
      email: '',
      phone: '',
    });
    const formData = reactive({
      pickup: {
        name: '',
        email: '',
        address: '',
        date: '',
        packageCategory: '',
        vehicle: 'bike',
        phone: undefined,
        packageDescription: '',
        additionalNote: '',
        // eslint-disable-next-line no-undef
        fullAddress: ref<google.maps.places.PlaceResult | null>(null),
        isExpress: false,
      },
      delivery: {
        name: '',
        phone: undefined,
        address: '',
        additionalNote: '',
        // eslint-disable-next-line no-undef
        fullAddress: ref<google.maps.places.PlaceResult | null>(null),
      },
    });

    onMounted(async () => {
      loadingAgentInformation.value = true;
      if (route.params.slug) {
        const loader = await loading();
        await loader.present();
        const result = await bookingForm.getMerchantInformationBySlug(
          route.params.slug as string,
        );
        loadingAgentInformation.value = false;
        if (result.ok) {
          await loader.dismiss();
          const data = result.data;
          const { isActive, companyName, logo } = data;
          agentInfo.isActive = isActive;
          agentInfo.companyName = companyName;
          agentInfo.logo = logo;
        } else {
          await loader.dismiss();
          await openToast(result.message as string);
        }
      } else {
        agentInfo.isActive = true;
        agentInfo.companyName = 'DLVR Logistics';
        agentInfo.logo = '/assets/dlvr-white-logo.svg';
      }
    });

    const validationRules = {
      pickup: {
        name: Joi.string().required(),
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required(),
        address: Joi.string().required(),
        packageCategory: Joi.string().required(),
        phone: Joi.number().required(),
        date: Joi.string().required(),
      },
      delivery: {
        name: Joi.string().required(),
        address: Joi.string().required(),
        phone: Joi.number().required(),
      },
      customer: {
        name: Joi.string().required(),
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required(),
        phone: Joi.number().required(),
      },
    };
    const validate = (obj: any, schema: any) => {
      const { error } = validateFields(schema, () => obj, false);

      return error;
    };

    const onSavePickup = async () => {
      const error = validate(
        {
          name: formData.pickup.name,
          email: formData.pickup.email,
          address: formData.pickup.address,
          packageCategory: formData.pickup.packageCategory,
          phone: formData.pickup.phone,
          date: formData.pickup.date,
        },
        Joi.object(validationRules.pickup),
      );

      if (error) {
        await openToast(error.message);
        return;
      }

      const { fullAddress } = formData.pickup;
      if (!fullAddress) {
        await openToast(
          'please select a valid pickup address from the options provided',
        );
      }
      if (fullAddress) {
        const exists = pickups.value.find(
          elm => elm.placeId === fullAddress.place_id,
        );
        if (exists) {
          await openToast(
            'This place already exists in your options!',
          );
          setIsPickupOpen();
          return;
        }

        const state = parseStateFromLocation(fullAddress);

        const details: any = formData.pickup;
        // delete details.fullAddress;
        pickups.value.push({
          ...details,
          state: state as string,
          placeId: fullAddress.place_id as string,
          latitude: fullAddress?.geometry?.location.lat(),
          longitude: fullAddress?.geometry?.location.lng(),
        });
        setIsPickupOpen();
      }
    };

    const onSaveDelivery = async () => {
      const error = validate(
        {
          name: formData.delivery.name,
          address: formData.delivery.address,
          phone: formData.delivery.phone,
        },
        Joi.object(validationRules.delivery),
      );
      if (error) {
        await openToast(error.message);
        return;
      }

      const { fullAddress } = formData.delivery;
      if (!fullAddress) {
        await openToast(
          'please select your delivery address from the options provided',
        );
      }
      if (fullAddress) {
        const exists = deliveries.value.find(
          elm => elm.placeId === fullAddress.place_id,
        );
        if (exists) {
          await openToast(
            'This place already exists in your options!',
          );
          setIsDeliveryOpen();
          return;
        }

        const details: any = formData.delivery;
        // delete details.fullAddress;
        deliveries.value.push({
          ...details,
          placeId: fullAddress.place_id as string,
          latitude: fullAddress?.geometry?.location.lat(),
          longitude: fullAddress?.geometry?.location.lng(),
        });

        setIsDeliveryOpen();
      }
    };

    const removeAddress = (placeId: string, type: string) => {
      if (type === 'pickup') {
        pickups.value = pickups.value.filter(
          elm => elm.placeId !== placeId,
        );
        return;
      }
      if (type === 'delivery') {
        deliveries.value = deliveries.value.filter(
          elm => elm.placeId !== placeId,
        );
        return;
      }
    };

    const paystackCallback = async (response?: any) => {
      if (response.status === 'success') {
        const loader = await loading();
        await loader.present();
        if (bookingForm.state.jobIds.length) {
          await bookingForm.updateCurrentOrders({
            paymentReference: response?.reference,
          });
          bookingForm.clearCurrentOrders();
        } else {
          await bookingForm.createOrder({
            pickup: pickups.value,
            delivery: deliveries.value,
            customer: customerInfo,
            amount: total.value,
            paymentReference: response?.reference,
          });
        }
        await loader.dismiss();
        modalValue.value = await modal(
          StatusModal,
          { type: 'success' },
          'success-modal',
        );
        await modalValue.value.present();
        pickups.value = [];
        deliveries.value = [];
      } else {
        modalValue.value = await modal(
          StatusModal,
          { type: 'fail' },
          'fail-modal',
        );
        await modalValue.value.present();
      }
    };

    const triggerPaystack = async () => {
      const error = validate(
        {
          name: customerInfo.name,
          email: customerInfo.email,
          phone: customerInfo.phone,
        },
        Joi.object(validationRules.customer),
      );

      if (error) {
        await openToast(error.message);
        return;
      }
      if (modalValue.value) {
        modalValue.value.dismiss();
      }
      const response = await bookingForm.createOrder({
        pickup: pickups.value,
        delivery: deliveries.value,
        customer: customerInfo,
        amount: total.value,
      });
      if (response.ok) {
        const { order: createdJobs } = response.data;
        bookingForm.state.jobIds = createdJobs.map(
          (job: any) => job.id,
        );
      } else {
        console.error(
          'Could not create the order as required. Will try again when confirming the payment',
        );
      }
      const handler = PaystackPop.setup({
        key: getConfig().PAYSTACK_KEY,
        email: formData.pickup.email,
        amount: total.value ? total.value * 100 : 0,
        currency: 'NGN',
        callback: paystackCallback,
        metadata: {
          jobIds: (bookingForm.state.jobIds || []).join(';'),
        },
        onClose: function() {
          return;
        },
      });
      handler.openIframe();
      return;
    };

    const resolveOrder = async (
      loader: {
        dismiss: typeof loadingController.dismiss;
      },
      preferredMerchant?: string,
    ) => {
      const response = await bookingForm.resolveOrder({
        pickup: pickups.value,
        delivery: deliveries.value,
        preferredMerchant,
      });
      if (response.ok) {
        await loader.dismiss();
        total.value = response.data.total.toFixed(2);
        customerInfo.name = pickups.value[0].name;
        customerInfo.email = pickups.value[0].email;
        customerInfo.phone = `${pickups.value[0].phone}`;
        modalValue.value = await modal(
          ConfirmationModal,
          {
            data: response.data,
            onConfirm: triggerPaystack,
            formData: customerInfo,
            validationRules,
          },
          'confirmation-modal',
        );
        return await modalValue.value.present();
      } else {
        await loader.dismiss();
        await openToast(response.message as string);
        return;
      }
    };

    const bookDelivery = async () => {
      const loader = await loading(
        'Preparing an estimate for your delivery.',
      );
      loader.dismiss();
      await loader.present();
      if (!route.params.slug) {
        if (pickups.value[0].latitude && pickups.value[0].longitude) {
          activeLocations.value = {};
          const geoQuery = geoFire.query({
            center: [
              pickups.value[0].latitude,
              pickups.value[0].longitude,
            ],
            radius,
          });
          geoQuery.on('key_entered', function(
            key: string,
            location: [number, number],
            distance: number,
          ) {
            activeLocations[key] = {
              key,
              location,
              distance: distance.toFixed(2),
            };
          });
          geoQuery.on('ready', async function() {
            const distances = Object.values(activeLocations)
              .map(d => Number(d.distance))
              .filter(distance => !!distance);
            if (!distances.length) {
              await openToast(
                'We were unable to find any nearby dispatchers. Please check back again later.',
              );
              await loader.dismiss();
              return;
            }
            const nearestDistance = Math.min(...distances);
            const nearestMerchant = Object.values(
              activeLocations,
            ).find(d => Number(d.distance) === nearestDistance);
            bookingForm.state.preferredMerchant =
              nearestMerchant?.key;
            resolveOrder(loader, nearestMerchant.key);
          });
        }
      } else {
        resolveOrder(loader);
      }
    };

    const setIsPickupOpen = () =>
      (isPickupOpen.value = !isPickupOpen.value);
    const setIsDeliveryOpen = () =>
      (isDeliveryOpen.value = !isDeliveryOpen.value);

    return {
      agentInfo,
      formData,
      pickups,
      deliveries,
      bookDelivery,
      onSavePickup,
      onSaveDelivery,
      removeAddress,
      paystackCallback,
      isPickupOpen,
      isDeliveryOpen,
      validationRules,
      setIsPickupOpen,
      setIsDeliveryOpen,
    };
  },
});
