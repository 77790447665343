
import { IonButton, IonContent, IonImg } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { IonButton, IonContent, IonImg },
  props: {
    close: { type: Function },
    type: { type: String },
  },
});
