
import { IonDatetime, IonItem, IonIcon } from "@ionic/vue";
import { calendarClearOutline } from "ionicons/icons";
import { defineComponent, toRefs, ref } from "vue";
import Joi from "joi";
import { validateFields } from "@/lib/forms";

export default defineComponent({
  components: { IonItem, IonDatetime, IonIcon },
  props: {
    label: String,
    className: String,
    displayFormat: String,
    validation: Object,
    name: String,
    modelValue: String,
    customValidation: Function,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const errorMessage = ref("");
    const { validation, name, modelValue, customValidation } = toRefs(
      props,
    );
    const onBlur = () => {
      errorMessage.value = "";

      const schema = Joi.object({
        [name.value as string]: validation.value,
      });

      const { error } = validateFields(
        schema,
        () => ({
          [name.value as string]: modelValue.value,
        }),
        false,
      );

      if (error) {
        errorMessage.value = error.message;
      }

      if (customValidation.value) {
        errorMessage.value = customValidation?.value();
      }
    };

    const minDate = new Date().toISOString().split("T")[0];

    const selectDate = (e: any) => {
      emit("update:modelValue", e.target.value);
    };

    return {
      onBlur,
      minDate,
      selectDate,
      errorMessage,
      calendarClearOutline,
    };
  },
});
