import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42b0b5cf"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, null, {
    default: _withCtx(() => [
      _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
      _createVNode(_component_ion_datetime, {
        mode: "ios",
        "display-format": _ctx.displayFormat,
        value: _ctx.modelValue,
        onIonChange: _ctx.selectDate,
        onIonBlur: _ctx.onBlur,
        placeholder: "YYYY-MM-DD",
        min: _ctx.minDate
      }, null, 8, ["display-format", "value", "onIonChange", "onIonBlur", "min"]),
      _createVNode(_component_ion_icon, {
        class: "datetime",
        icon: _ctx.calendarClearOutline,
        slot: "end"
      }, null, 8, ["icon"]),
      (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.errorMessage), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}