export const formatAmount = (amount: any) => {
  return (
    Math.round((amount + Number.EPSILON) * 100) / 100
  ).toLocaleString();
};

export const parseStateFromLocation = (
  fullAddress: google.maps.places.PlaceResult,
): string | undefined => {
  return fullAddress.address_components?.find(
    addr => addr?.types[0] === 'administrative_area_level_1',
  )?.long_name === 'Federal Capital Territory'
    ? 'abuja'
    : fullAddress.address_components?.find(
        addr => addr?.types[0] === 'administrative_area_level_1',
      )?.long_name;
};
