
/* eslint-disable no-undef */
import {
  IonSelect,
  IonItem,
  IonSelectOption,
  IonSpinner,
  IonIcon,
  IonButton,
} from '@ionic/vue';
import { defineComponent, toRefs, ref } from 'vue';
import Joi from 'joi';
import { validateFields } from '@/lib/forms';
import { alertCircle } from 'ionicons/icons';

export default defineComponent({
  components: {
    IonSelect,
    IonItem,
    IonSelectOption,
    IonSpinner,
    IonIcon,
    IonButton,
  },
  props: {
    label: String,
    className: String,
    disabled: Boolean,
    disabledOption: Function,
    validation: Object,
    name: String,
    options: Array,
    modelValue: String,
    isLoading: {
      type: Boolean,
      default: false,
    },
    withTooltip: Boolean,
    tooltipContent: String,
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const errorMessage = ref('');
    const inputRef = ref(null);
    const isTooltipOpen = ref(false);
    const { validation, name, modelValue, withTooltip } = toRefs(
      props,
    );
    const toggleTooltip = async () =>
      (isTooltipOpen.value = !isTooltipOpen.value);
    const onBlur = () => {
      errorMessage.value = '';

      const schema = Joi.object({
        [name.value as string]: validation.value,
      });

      const { error } = validateFields(
        schema,
        () => ({
          [name.value as string]: modelValue.value,
        }),
        false,
      );

      if (error) {
        errorMessage.value = error.message;
      }
    };

    const selectState = (e: any) => {
      if (withTooltip.value) {
        isTooltipOpen.value = false;
      }
      emit('update:modelValue', e.target.value);
    };

    return {
      selectState,
      errorMessage,
      toggleTooltip,
      isTooltipOpen,
      alertCircle,
      onBlur,
      inputRef,
    };
  },
});
